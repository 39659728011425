import { graphql } from 'msw';

export const getOrganisationFeeProducts = [
  {
    amount: 2825,
    description: `
  <div class="product-desc">
    A basic option for organizations taking their first steps in sustainability.
    <br><br>
    (Event benefits may vary by region, confirm details with your regional office)
  </div>
  <!-- SEPARATOR -->
  <div class="product-benefits">
    <h4>Benefits:</h4>
    <ul>
      <li>Report through the CDP Portal.</li>
      <li>Use CDP's suite of tools, reporting frameworks and guidance.</li>
    </ul>
    <h4>Plus:</h4>
    <ul>
      <li>Pre-paid entry or priority registration to one regional CDP event.</li>
    </ul>
  </div>`,
    isoCurrencyCode: 'GBP',
    name: 'Foundation',
    productListItemId: 'a75eb671-11da-43db-8986-bd60b3d54737',
    taxAmount: 197.75,
    taxPercentage: 7,
    totalAmount: 3022.75,
    __typename: 'ProductModel',
  },
  {
    amount: 6850,
    description: `
      <div class="product-desc">
        Our highest level of membership offers extra benefits for organizations taking a proactive approach to disclosure and improvement.
        <br><br>
        (Event benefits may vary by region, confirm details with your regional office)
      </div>
      <!-- SEPARATOR -->
      <div class="product-benefits">
        <strong>Benefits:</strong><br>
        <ul>
          <li>Report through the CDP Portal.</li>
        </ul>
        <br><br>
        <strong>Plus:</strong><br>
        <ul>
          <li>Pre-paid/priority registration for two people and company recognition at regional CDP events.</li>
          <li>A CDP Supporter badge for external communications.</li>
      </ul>
      </div>
    `,
    isoCurrencyCode: 'EUR',
    name: 'Enhanced',
    productListItemId: 'b70fda47-0713-ef11-9f8a-000d3abc7014',
    taxAmount: 479.5,
    taxPercentage: 7,
    totalAmount: 7329.5,
    __typename: 'ProductModel',
  },
];
export const getOrganisationFeeProductsQueryMock = graphql.query('GetOrganisationFeeProducts', (_, res, ctx) => {
  return res(
    ctx.data({
      getOrganisationFeeProducts,
    }),
  );
});

export const getOrganisationFeeEUProductsMock = graphql.query('GetOrganisationFeeProducts', (_, res, ctx) => {
  return res(
    ctx.data({
      getOrganisationFeeProducts: [
        {
          amount: 2825,
          description:
            "A basic option for organizations taking their first steps in sustainability.\n<br><br>\n(Event benefits may vary by region, confirm details with your regional office)\n<br><br>\n<strong>Benefits:</strong><br>\n<ul><li>Report through the CDP Portal.</li>\n<li>Use CDP's suite of tools, reporting frameworks and guidance.</li>\n<li>Enjoy communications opportunities resulting from disclosing through CDP.</li></ul>\n\n<strong>Plus: </strong><br>\n<ul><li>Pre-paid entry or priority registration to one regional CDP event.</li></ul>",
          isoCurrencyCode: 'EUR',
          name: 'Foundation',
          productListItemId: 'a75eb671-11da-43db-8986-bd60b3d54737',
          taxAmount: 197.75,
          taxPercentage: 7,
          totalAmount: 3022.75,
          __typename: 'ProductModel',
        },
      ],
    }),
  );
});

export const getOrganisationFeeProductsUSMock = graphql.query('GetOrganisationFeeProducts', (_, res, ctx) => {
  return res(
    ctx.data({
      getOrganisationFeeProducts: [
        {
          amount: 123.43,
          description:
            "A basic option for organizations taking their first steps in sustainability.\n<br><br>\n(Event benefits may vary by region, confirm details with your regional office)\n<br><br>\n<strong>Benefits:</strong><br>\n<ul><li>Report through the CDP Portal.</li>\n<li>Use CDP's suite of tools, reporting frameworks and guidance.</li>\n<li>Enjoy communications opportunities resulting from disclosing through CDP.</li></ul>\n\n<strong>Plus: </strong><br>\n<ul><li>Pre-paid entry or priority registration to one regional CDP event.</li></ul>",
          isoCurrencyCode: 'USD',
          name: 'US Product name',
          productListItemId: 'a75eb671-11da-43db-8986-bd60b3d54737',
          taxPercentage: 10,
          taxAmount: 13.33,
          totalAmount: 133.3,
          __typename: 'ProductModel',
        },
      ],
    }),
  );
});

export const selectProductMock = graphql.mutation('SelectProduct', (_, res, ctx) => {
  return res(
    ctx.data({
      selectProduct: {
        success: true,
        __typename: 'SelectProductResponse',
      },
    }),
  );
});
