import { useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';

interface UserInfo {
  id: string;
  email: string;
  roles: string[];
  organisation?: {
    orgHqCountryName: string;
    orgCountryName: string;
    parentOrganisationName: string;
    parentOrganisationId: string;
    childOrganisationCount: number;
    organisationTags: string[];
    organisationTypeId: string;
    organisationTypeName: string;
    organisationAuthorityTypes: string[];
  };
  communicationLanguage: string;
  portalLanguage: string;
  accountManagerName?: string; // Add account manager name
  accountManagerEmail?: string; // Add account manager email
}

export const useUserInfo = () => {
  const { data: session } = useSession();
  const [
    userInfo,
    setUserInfo,
  ] = useState<UserInfo | null>(null);

  useEffect(() => {
    if (session) {
      const user = {
        ...userInfo,
        id: session.user.id,
        email: session.user.email,
        roles: session.user.roles,
        organisationId: session.orgId || '',
        organisationName: session.orgName || '', // Add organization name
        communicationLanguage: session.communicationLang || '',
        portalLanguage: session.portalLang || '',
      };

      setUserInfo(user);
      localStorage.setItem('userInfo', JSON.stringify(user));
    } else {
      const storedUserInfo = localStorage.getItem('userInfo');
      if (storedUserInfo) {
        setUserInfo(JSON.parse(storedUserInfo));
      }
    }
  }, [
    session,
  ]);

  const updateOrganisationInfo = (organisationInfo: any) => {
    if (userInfo) {
      const updatedUserInfo = {
        ...userInfo,
        organisation: {
          orgHqCountryName: organisationInfo.OrgHqCountry?.countryName,
          orgCountryName: organisationInfo.OrgCountry?.countryName,
          parentOrganisationName: organisationInfo.ParentOrganisation?.orgName,
          parentOrganisationId: organisationInfo.ParentOrganisation?.organisationId,
          childOrganisationCount: organisationInfo.ChildOrganisations.length,
          organisationTags: organisationInfo.organisationTags,
          organisationTypeId: organisationInfo.organisationType?.organisationTypeId,
          organisationTypeName: organisationInfo.organisationType?.name,
          organisationAuthorityTypes: organisationInfo.organisationAuthorityTypes,
        },
      };
      setUserInfo(updatedUserInfo);
      localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));
    }
  };

  const updateAccountManagerInfo = (accountManagerName: string, accountManagerEmail: string) => {
    if (userInfo) {
      const updatedUserInfo = {
        ...userInfo,
        accountManagerName,
        accountManagerEmail,
      };
      setUserInfo(updatedUserInfo);
      localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));
    }
  };

  return { userInfo, updateOrganisationInfo, updateAccountManagerInfo };
};
